import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import { Button, Col, Row, Stack } from "react-bootstrap";
import createLogEventBody from "../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useLogEventMutation } from "../../../api/api.ts";

const RentACenterLandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureRequirements[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <Row className='justify-content-center mt-3'>
            <Col xs={11}>
                <Stack gap={3}>
                    <h1 className='text-center text-primary'>{t("rentacenter.header")}</h1>
                    <p>{t("rentacenter.p1")}</p>
                    <ul>
                        <li>{t("rentacenter.li1")}</li>
                        <li>{t("rentacenter.li2")}</li>
                        <li>{t("rentacenter.li3")}</li>
                    </ul>
                    <p>{t("rentacenter.p2")}</p>
                    <Button variant='primary' onClick={clickStart} className='mx-auto w-100 p-2 p-md-3'>
                        {t("landingPage.start")}
                    </Button>
                    <div>
                        <p className='fw-bold text-center text-primary'>
                            {captureRequirements.length} - {t("landingPage.step")}
                        </p>
                        <p className='text-center text-primary fw-bold'>{t("landingPage.identityValidation")}</p>
                    </div>
                    <p>
                        {t("landingPage.privacyPolicyMessage")}{" "}
                        <a
                            onClick={() => logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status))}
                            href={t("landingPage.privacyPolicyLink")}
                            target='_blank'
                            className='text-primary text-decoration-none'
                        >
                            {t("landingPage.privacyPolicy")}
                        </a>
                        .
                    </p>
                </Stack>
            </Col>
        </Row>
    );
};
export default RentACenterLandingPage;
