/**
 * This is the main application component that serves as the entry point for the entire application.
 *
 * It handles routing using React Router, loading WebAssembly (Wasm) resources, and rendering different
 * components based on the current route. The component also displays a loading message while Wasm is loading.
 *
 * @component
 * @example
 * // Import the App component in your application
 * import App from './App.tsx';
 *
 * // Render the App component in your application
 * ReactDOM.render(<App />, document.getElementById('root'));
 */
import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { Stack, Container } from "react-bootstrap";
import { RootState } from "./redux/store.ts";
import LoadingPage from "./pages/LoadingPage.tsx";
import { useTranslation } from "react-i18next";
import DefaultRouter from "./routers/DefaultRouter.tsx";
import ErrorPage from "./pages/ErrorPages/ErrorPage.tsx";
import { useDispatch, useSelector } from "react-redux";
import { setUserObject } from "./redux/slices/user.slice.ts";
import { useLocation, useNavigate } from "react-router-dom";
import IpqsTracker from "./components/IpqsTracker/IpqsTracker.tsx";
import { useFetchAuthIdUrlMutation, useFetchUserConfigMutation, useLogEventMutation } from "./api/api.ts";
import LogoAndTimer from "./components/LogoAndTimer/LogoAndTimer.tsx";
import { getCompanyConfig } from "./utils/getCompanyConfig.ts";
import CustomerJourneyCodes from "./assets/CustomerJourneyCodes.json";
import createLogEventBody from "./utils/createLogEventBody.js";
import calculateUTCExpirationTime from "./utils/calculateUTCExpirationTime.js";
import TimeoutPage from "./pages/ErrorPages/TimeoutPage.tsx";
/**
 * The main application component.
 *
 * @returns {JSX.Element} The JSX element representing the main application.
 */
const App = (): JSX.Element => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const { t } = useTranslation();
    const [fetchUserConfig, userConfig] = useFetchUserConfigMutation();
    const [fetchAuthIdUrl, authId] = useFetchAuthIdUrlMutation();
    const [logEvent] = useLogEventMutation();
    const { authIdUrl: userAuthIdUrl, token: transactionId } = useSelector((state: RootState) => state.user);
    const [showQRCode, setShowQRCode] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let token = urlParams.get("token");

    // This is a workaround to migrate toolkit users into capture-a
    // FE-584 TSR-543
    if (urlParams.get("userToken")) {
        token = urlParams.get("userToken");
    }

    const routerVersion = urlParams.get("version");
    const language = urlParams.get("language");

    const skipLanding = getCompanyConfig("skipLanding");
    const captureRequirements = useSelector((state: RootState) => state.user.captureRequirements);

    const defaultEnvVersion = window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_EXPERIENCE;
    const breadLoaded = useRef(false);

    if (
        !userConfig.isUninitialized &&
        !userConfig.isLoading &&
        !userConfig.isError &&
        userConfig.isSuccess &&
        userConfig.data?.payload.qrBreakpointPx
    ) {
        if (!showQRCode && userConfig.data.payload.qrBreakpointPx && window && window.innerWidth > userConfig.data.payload.qrBreakpointPx) {
            setShowQRCode(true);
        }
    }

    React.useEffect(() => {
        // In a useEffect to avoid infinite re-renders
        if (transactionId && userConfig.isSuccess) {
            logEvent(createLogEventBody(CustomerJourneyCodes.transaction.url.status, `URL: ${window.location}`));
            logEvent(createLogEventBody(CustomerJourneyCodes.transaction.userAgent.status, `UserAgent: ${navigator.userAgent}`));
            logEvent(createLogEventBody(CustomerJourneyCodes.landing.getConfigApiCall.status));
        }
    }, [transactionId, userConfig.isSuccess]);

    // set the token and routerVersion on state
    React.useEffect(() => {
        let obj = {};
        if (routerVersion) {
            obj = { ...obj, ...{ routerVersion } };
        } else if (defaultEnvVersion) {
            obj = { ...obj, ...{ routerVersion: defaultEnvVersion } };
        }
        if (language) {
            obj = { ...obj, ...{ language } };
        }
        if (token) {
            obj = { ...obj, ...{ token: token } };
        }
        dispatch(setUserObject(obj));
    }, [token, routerVersion, language]);

    // When the token changes, get the userConfig
    React.useEffect(() => {
        if (token) {
            fetchUserConfig({ token: token });
        }
    }, [token, fetchUserConfig]);

    // Process the userConfig
    React.useEffect(() => {
        if (!userConfig.isUninitialized && !userConfig.isLoading && !userConfig.isError && userConfig.isSuccess) {
            const { captureRequirements, expirationTime, isTokenValidForIngest, redirectUrl, captureAssets, errorRedirectUrl } =
                userConfig.data?.payload;

            if (captureRequirements.includes("selfie") && !userAuthIdUrl) {
                fetchAuthIdUrl({});
            }

            const now = new Date();
            let obj = {
                captureRequirements,
                tokenExpirationTime: expirationTime,
                isTokenValid: isTokenValidForIngest,
                landingTimeStamp: now.toString(),
                redirectUrl,
                captureAssets,
                errorRedirectUrl,
            };
            dispatch(setUserObject(obj));
            const utcExpirationTime = calculateUTCExpirationTime(expirationTime);
            sessionStorage.setItem("utcExpirationTime", utcExpirationTime);
            sessionStorage.setItem("errorRedirectUrl", errorRedirectUrl);
        } else if (userConfig.isError) {
            const tokenUsed = (userConfig as any).error?.status === 498;
            if (tokenUsed) {
                navigate(`/complete?token=${token}&version=${routerVersion}&language=${language}`);
            }
        }
    }, [userConfig]);

    React.useEffect(() => {
        if (!authId.isUninitialized && !authId.isLoading && !authId.isError && authId.isSuccess) {
            dispatch(setUserObject({ authIdUrl: authId?.data?.payload?.authIdUrl }));
        }
    }, [authId]);

    if (!token || userConfig.isError) {
        if ((userConfig as any).error?.status === 498) {
            return <TimeoutPage />;
        }
        return <ErrorPage />;
    }

    // Show Loading page while waiting for userConfig
    if (userConfig.isUninitialized || userConfig.isLoading) {
        return <LoadingPage />;
    }

    if (!userConfig.isUninitialized && !userConfig.isLoading && skipLanding && captureRequirements.length > 0) {
        // logEvent(createLogEventBody(CustomerJourneyCodes.landing.skippedLanding.status));
        if (!breadLoaded.current) {
            navigate(`/${captureRequirements[0]}?token=${token}&version=${routerVersion}&language=${language}`);
            breadLoaded.current = true;
        }
    }

    return (
        <div className='App'>
            {showQRCode ? (
                <Container>
                    <LogoAndTimer />
                    <Stack gap={3} className='align-items-center mt-5'>
                        <p className='text-center'>{t("app.qrCode")}</p>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: 250, width: "100%" }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </Stack>
                </Container>
            ) : (
                <>
                    <DefaultRouter />
                    {token && !userConfig.isError && !userConfig.isUninitialized && !userConfig.isLoading && <IpqsTracker transactionId={token} />}
                </>
            )}
        </div>
    );
};

export default App;
