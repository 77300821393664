import { useEffect, useRef } from "react";
import Background from "../../components/Background/Background.tsx";
import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import { useLocation } from "react-router-dom";
import LogoAndTimer from "../../components/LogoAndTimer/LogoAndTimer.tsx";
import { useLogEventMutation } from "../../api/api.ts";
import createLogEventBody from "../../utils/createLogEventBody.js";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";

const FourZeroFourPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");
    const errorRedirectUrl = useSelector((state: RootState) => state.user.errorRedirectUrl);
    const sessionStorageErrorUrl = sessionStorage.getItem("errorRedirectUrl");
    const errorLink = errorRedirectUrl ? errorRedirectUrl : sessionStorageErrorUrl !== "undefined" ? sessionStorageErrorUrl : null;

    const [logEvent, logEventResponse] = useLogEventMutation();
    const hasFiredLogEvent = useRef(false);

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.errors[404].navToPage.status));
    }, []);

    useEffect(() => {
        const sendRedirectLog = async () => {
            try {
                if (logEventResponse.isSuccess && errorLink && !hasFiredLogEvent.current) {
                    hasFiredLogEvent.current = true;
                    logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrl.status));
                    if (logEventResponse.isSuccess) {
                        window.location.reload();
                        window.location.replace(errorLink);
                    } else if (logEventResponse.isError && !logEventResponse.isSuccess && !logEventResponse.isLoading) {
                        logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrlFailure.status));
                    }
                }
            } catch {
                logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrlFailure.status));
            }
        };
        sendRedirectLog();
    }, [logEventResponse.isSuccess, logEventResponse.isError, errorLink]);

    const clickBack = () => {
        logEvent(createLogEventBody(CustJourneyCodes.errors[404].clickBack.status));
        navigate(-1);
    };

    const fourZeroFourPage = getCompanyConfig("fourZeroFourPage");

    if (fourZeroFourPage) {
        return fourZeroFourPage;
    }

    return (
        <>
            {!errorRedirectUrl && (
                <Background>
                    <LogoAndTimer />
                    <Stack gap={5} className='align-items-center mt-5'>
                        <h3 className='text-center'>{t("fourZeroFourPage.tryAgain")}</h3>
                        <Button onClick={clickBack}>{t("fourZeroFourPage.back")}</Button>
                        <p>{token}</p>
                    </Stack>
                </Background>
            )}
        </>
    );
};

export default FourZeroFourPage;
