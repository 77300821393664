import Background from "../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Col, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const RentACenterErrorPage = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");

    return (
        <Background>
            <LogoAndTimer />
            <Row className='justify-content-center'>
                <Col xs={11}>
                    <Stack gap={3} className='mt-3'>
                        <p>{t("errorPage")}</p>
                        <p className='text-center'>{token}</p>
                    </Stack>
                </Col>
            </Row>
        </Background>
    );
};
export default RentACenterErrorPage;
