import React, { useEffect, useState } from "react";
import { Badge, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type OwnProps = {
    docType: "NA_ID" | "Passport" | "Passport Card";
    page: string;
    captureQualityStatus: "high" | "medium" | "low" | "zero";
};

export const warningStyles = {
    color: "#96510D",
    backgroundColor: "#FEF8F1",
    borderColor: "#F4B45D",
    borderStyle: "solid",
    borderWidth: "2px",
    fontWeight: "bold",
};

export const errorStyles = {
    color: "#BE240E",
    backgroundColor: "#FFF2F0",
    borderColor: "#FF654D",
    borderStyle: "solid",
    borderWidth: "2px",
    fontWeight: "bold",
};

export const successStyles = {
    color: "#0D7232",
    backgroundColor: "#F1F9F3",
    borderColor: "#57Bd7B",
    borderStyle: "solid",
    borderWidth: "2px",
    fontWeight: "bold",
};

export const headingStyles = {
    color: "#2E1A47",
    backgroundColor: "#FFFFFF",
    borderColor: "#DBDBDB",
    borderStyle: "solid",
    borderWidth: "2px",
    fontWeight: "bold",
};

const CaptureMessages: React.FC<OwnProps> = ({ docType, page, captureQualityStatus }) => {
    const { t } = useTranslation();
    const defaultScanSideMessage =
        docType === "Passport"
            ? t("captureMessages.scanPassport")
            : docType === "Passport Card"
            ? t("captureMessages.scanPassportCard", { side: page.toLowerCase() === "front" ? t("captureMessages.front") : t("captureMessages.back") })
            : t("captureMessages.scanIdBySide", { side: page.toLowerCase() === "front" ? t("captureMessages.front") : t("captureMessages.back") });
    const defaultInstructionMessage = (
        <Badge className='text-wrap' bg={headingStyles.backgroundColor} style={headingStyles} aria-live='assertive'>
            {t("captureMessages.positionYourId", {
                document:
                    docType === "Passport"
                        ? t("captureMessages.passport")
                        : docType === "Passport Card"
                        ? t("captureMessages.passportCard")
                        : t("captureMessages.id"),
            })}
        </Badge>
    );
    const [instructionMessage, setInstructionMessage] = useState<string | React.ReactNode>(defaultInstructionMessage);
    const [changeMessage, setChangeMessage] = useState<boolean>(true);

    const frontMessageId = t("captureMessages.frontMessageId");
    const frontMessagePassport = t("captureMessages.frontMessagePassport");
    const frontMessagePassportCard = t("captureMessages.frontMessagePassportCard");

    const frontMessages = docType === "Passport" ? frontMessagePassport : docType === "Passport Card" ? frontMessagePassportCard : frontMessageId;
    const backMessageId = t("captureMessages.backMessageId");
    const backMessagePassportCard = t("captureMessages.backMessagePassportCard");
    const backMessage = docType === "Passport Card" ? backMessagePassportCard : backMessageId;

    const defaultAdditionalMessage = page.toLowerCase() === "front" ? frontMessages : backMessage;
    const [additionalMessage, setAdditionalMessage] = useState<string>("");

    const statusMessages = {
        zero: defaultInstructionMessage,
        low: (
            <Badge className='text-wrap' aria-live='assertive' bg={errorStyles.backgroundColor} style={errorStyles}>
                {t("captureMessages.moveDeviceCloser")}
            </Badge>
        ),
        medium: (
            <Badge className='text-wrap' aria-live='assertive' bg={warningStyles.backgroundColor} style={warningStyles}>
                {t("captureMessages.holdCameraStill")}
            </Badge>
        ),
        high: (
            <Badge className='text-wrap' aria-live='assertive' bg={successStyles.backgroundColor} style={successStyles}>
                <i className='bi bi-check me-1' role='presentation' />
                {t("captureMessages.capturing")}
            </Badge>
        ),
    };

    const HeadingBadge = (
        <Badge className='p-2 fs-5 text-wrap' aria-live='assertive' bg={headingStyles.backgroundColor} style={headingStyles}>
            {defaultScanSideMessage}
        </Badge>
    );

    // Display the additional message after 7.5 seconds
    // useEffect(() => {
    //     setTimeout(() => {
    //         setAdditionalMessage(defaultAdditionalMessage);
    //     }, 7500);
    // }, []);

    useEffect(() => {
        if (changeMessage) {
            setInstructionMessage(statusMessages[captureQualityStatus]);
            setChangeMessage(false);
        } else {
            setTimeout(() => {
                setChangeMessage(true);
            }, 1000);
        }
    }, [captureQualityStatus]);

    return (
        <div
            className='vh-100 d-flex flex-column position-absolute justify-content-between'
            style={{
                width: "100vw",
                zIndex: 100,
                top: "5vh",
            }}
        >
            <Row className='mx-auto'>{HeadingBadge}</Row>
            <div className='d-flex flex-column gap-1 mb-5' style={{ height: "35%" }}>
                <Row className='my-2 p-2 fs-5 mx-auto'>{instructionMessage}</Row>
                {additionalMessage ? (
                    <Row style={headingStyles} aria-live='polite' className='w-75 py-2 px-3 rounded-2 mx-auto'>
                        {additionalMessage}
                    </Row>
                ) : null}
            </div>
        </div>
    );
};

export default CaptureMessages;
