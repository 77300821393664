import { useTranslation } from "react-i18next";
import { Col, Row, Stack } from "react-bootstrap";

const RentACenterCompletePage = () => {
    const { t } = useTranslation();
    return (
        <Row className='justify-content-center mt-5'>
            <Col xs={11}>
                <Stack gap={3}>
                    <h1 className='text-primary text-center fw-bold'>{t("completePage.submitted")}</h1>
                    <p>
                        {t("completePage.complete")} {t("completePage.close")}
                    </p>
                </Stack>
            </Col>
        </Row>
    );
};
export default RentACenterCompletePage;
