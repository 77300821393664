import React, { useEffect, useRef, useState } from "react";
import SelfieConsentModal from "./SelfieConsent/SelfieConsentModal.tsx";
import ErrorBoundaryWrapper from "../ErrorBoundary/ErrorBoundaryWrapper.tsx";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import createLogEventBody from "../../utils/createLogEventBody.js";
import { useFetchAndPostAuthIdResultsMutation, useLogEventMutation } from "../../api/api.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { getNextPage } from "../../redux/slices/user.slice.ts";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "authid-component": AuthIdComponentProps;
        }
    }
}

interface AuthIdComponentProps extends React.HTMLProps<HTMLDivElement> {
    "data-url": string | null;
    "data-webauth": string;
}

const SelfieCapture = () => {
    const hasLoggedCamPerms = useRef<boolean>(false);
    const hasLoggedCamRequest = useRef(false);
    const [currAuthIdPageName, setCurrAuthIdPageName] = useState("");
    const [currAuthIdMessage, setCurrAuthIdMessage] = useState("");

    const [logEvent] = useLogEventMutation();

    const navigate = useNavigate();

    const [fetchAndPostAuthIdResults, { isSuccess: isSubmitSelfieSuccess, isLoading: isSubmitSelfieLoading, isError: isSubmitSelfieError }] =
        useFetchAndPostAuthIdResultsMutation();

    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language, authIdUrl } = user;

    const [hasConsentedToBioWebApp, setHasConsentedToBioWebApp] = useState(false);
    const url = `/${getNextPage(captureRequirements, "selfie")}?token=${token}&version=${routerVersion}&language=${language}`;

    const handleMessages = async (event: any) => {
        // LOG CAMERA PERMS DECISION

        const isAuthIdSuccess = event?.data?.success;
        const currAuthIdPageName = event?.data?.pageName;
        const currAuthIdMessage = event?.data?.params?.message;
        // Set the current AuthId Message in the case of camera permission granted when we don't have a pageName just yet.
        setCurrAuthIdMessage(currAuthIdMessage);

        // Set the current AuthId Page Name in the case of camera permissions denied where we have a pageName immediatley.
        setCurrAuthIdPageName(currAuthIdPageName);

        // If the current pageName is the verifiedPage then submit the data.
        if (isAuthIdSuccess && currAuthIdPageName === "verifiedPage") {
            const fetchAndSubmitBody = {};
            fetchAndPostAuthIdResults(fetchAndSubmitBody);
        }
    };

    useEffect(() => {
        if (hasConsentedToBioWebApp) {
            logEvent(createLogEventBody(CustJourneyCodes.biometricsAccept.clickContinue.status));
            // LOG CAMERA PERMS PRESENTED
            if (!hasLoggedCamRequest.current) {
                hasLoggedCamRequest.current = true;
                logEvent(createLogEventBody(CustJourneyCodes.captureSelfie.selfieCameraAccessStarted.status));
            }
        }
    }, [hasConsentedToBioWebApp]);

    useEffect(() => {
        /**
         * This is in a useEffect because it can't be directly in the handleMessages method since that fires too quickly.
         * It also can't be outside of a useEffect because the handler doesn't cause the component to re-render and this code to execute.
         * We need a dependency array controlled by the handler to execute the following code to prevent log-events from firing multiple times.
         *  **/

        // If we haven't already logged the camera permissions & we have the p
        if (!hasLoggedCamPerms.current && (currAuthIdPageName || currAuthIdMessage)) {
            hasLoggedCamRequest.current = true;
            if (currAuthIdPageName === "videoDeviceNotFoundPage") {
                logEvent(createLogEventBody(CustJourneyCodes.errors.selfieCameraAccessDenied.navToPage.status));
            } else if (currAuthIdMessage === "TECHNIQUE_SET") {
                logEvent(createLogEventBody(CustJourneyCodes.captureSelfie.selfieCameraAccessAccepted.status));
            }
        }
    }, [currAuthIdPageName, currAuthIdMessage, hasLoggedCamPerms.current]);

    useEffect(() => {
        if (isSubmitSelfieSuccess && !isSubmitSelfieLoading) {
            navigate(url);
        }
        if (isSubmitSelfieError && !isSubmitSelfieLoading) {
            navigate(`/error?token=${token}&version=${routerVersion}&language=${language}`);
        }
    }, [isSubmitSelfieLoading, isSubmitSelfieSuccess, isSubmitSelfieError]);

    useEffect(() => {
        window.removeEventListener("message", handleMessages);
        window.addEventListener("message", handleMessages);
    }, [captureRequirements]);

    useEffect(() => {
        if (!authIdUrl) {
            logEvent(createLogEventBody(CustJourneyCodes.captureSelfie.authIdMissingUrl.status));
        }
    }, [authIdUrl]);

    if (!hasConsentedToBioWebApp) {
        return <SelfieConsentModal setHasConsentedToBioWebApp={setHasConsentedToBioWebApp} hasConsentedToBioWebApp={hasConsentedToBioWebApp} />;
    }

    return (
        <>
            {authIdUrl ? (
                <ErrorBoundaryWrapper>
                    {" "}
                    <authid-component data-url={authIdUrl} data-webauth='true'></authid-component>{" "}
                </ErrorBoundaryWrapper>
            ) : (
                /** The AuthID Selfie URL isn't available likely because the call failed. */
                <p>No service URL</p>
            )}
        </>
    );
};

export default SelfieCapture;
