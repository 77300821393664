import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Image, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import LogoAndTimer from "../../LogoAndTimer/LogoAndTimer.tsx";
import Background from "../../Background/Background.tsx";
import { getCompanyConfig } from "../../../utils/getCompanyConfig.ts";
import CertifIDFooter from "../../../assets/CertifID/CertifIDFooter.svg";
import { useLogEventMutation } from "../../../api/api.ts";
import { APIVersion } from "../../../assets/apiVersionEnums.ts";
import createLogEventBody from "../../../utils/createLogEventBody.js";

interface SelfieConsentModalProps {
    hasConsentedToBioWebApp: boolean;
    setHasConsentedToBioWebApp: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelfieConsentModal = ({ hasConsentedToBioWebApp, setHasConsentedToBioWebApp }: SelfieConsentModalProps) => {
    const apiVersion = window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION;

    const { t } = useTranslation();
    const biometricPolicy = "https://www.intellicheck.com/biometric-information-policy/";
    const user = useSelector((state: RootState) => state.user);
    const { token, routerVersion, language } = user;
    const url = `/biometrics-denied?token=${token}&version=${routerVersion}&language=${language}`;
    const navigate = useNavigate();
    const [logEvent] = useLogEventMutation();

    const biometricsPolicy = getCompanyConfig("biometricsPolicy");
    const hideBottomBiometricsLink = getCompanyConfig("hideBottomBiometricsLink");
    const biometricsContinueBtnKey = getCompanyConfig("biometricsContinueBtnKey");
    const biometricsDeclineBtnKey = getCompanyConfig("biometricsDeclineBtnKey");
    const secondButtonVariant = getCompanyConfig("secondButtonVariant");
    const biometricsDenyUrl = getCompanyConfig("biometricsDenyUrl");

    // for Truist checkbox
    const [showCheckboxWarning, setShowCheckboxWarning] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.biometricsAccept.navToPage.status));
    }, []);

    useEffect(() => {
        if (isChecked) {
            logEvent(createLogEventBody(CustJourneyCodes.biometricsAccept.clickCheckbox.status));
        }
    }, [isChecked]);

    const clickContinue = () => {
        if (routerVersion === "truist" && !isChecked) {
            setShowCheckboxWarning(true);
        } else {
            setHasConsentedToBioWebApp(true);
        }
    };

    const clickBiometricsPolicyLink = () => {
        logEvent(createLogEventBody(CustJourneyCodes.biometricsAccept.clickPolicyLink.status));
    };

    const clickBiometricsCancel = () => {
        logEvent(createLogEventBody(CustJourneyCodes.biometricsAccept.clickCancel.status));

        if (biometricsDenyUrl) {
            window.location.href = biometricsDenyUrl;
        } else {
            navigate(url);
        }
    };

    return (
        <Background>
            {!hasConsentedToBioWebApp && (
                <>
                    <LogoAndTimer />
                    <Stack
                        gap={routerVersion === "certifid" ? 0 : 3}
                        className={routerVersion === "certifid" || routerVersion === "acima" ? "" : `text-center mt-5 px-4`}
                    >
                        {biometricsPolicy && routerVersion === "truist" ? (
                            <div className='d-flex'>{biometricsPolicy}</div>
                        ) : biometricsPolicy && routerVersion !== "truist" ? (
                            <Row>{biometricsPolicy}</Row>
                        ) : (
                            <p className='fs-5'>{t("selfieConsentModal.iAgree")}</p>
                        )}

                        {routerVersion === "truist" && (
                            <Stack gap={2} direction='horizontal' aria-label={`Checkbox labeled: ${t("selfieConsentModal.checkboxConsentText")}`}>
                                <input
                                    type='checkbox'
                                    style={{ height: "1.5rem", width: "1.5rem" }}
                                    name='biometric-check'
                                    id='biometric-check'
                                    checked={isChecked}
                                    onChange={handleCheckbox}
                                />
                                <label htmlFor='biometric-check' className='text-left'>
                                    {t("selfieConsentModal.checkboxConsentText")}
                                </label>
                            </Stack>
                        )}

                        {routerVersion === "truist" && showCheckboxWarning && (
                            <Stack gap={2} direction='horizontal' className='align-items-start' role='alert'>
                                <i className='text-danger bi bi-exclamation-circle'></i>
                                <p className='text-danger'>{t("selfieConsentModal.checkboxWarning")}</p>
                            </Stack>
                        )}

                        <Stack direction='vertical' gap={3} className='justify-content-center mt-4'>
                            <Button variant='primary' onClick={clickContinue}>
                                {biometricsContinueBtnKey ? t(biometricsContinueBtnKey) : t("selfieConsentModal.continue")}
                            </Button>
                            <Button variant={secondButtonVariant ? secondButtonVariant : "outline-primary"} onClick={clickBiometricsCancel}>
                                {biometricsDeclineBtnKey ? t(biometricsDeclineBtnKey) : t("selfieConsentModal.cancel")}
                            </Button>
                        </Stack>

                        {routerVersion === "certifid" ? (
                            <Row className='mt-5 mb-5'>
                                <Col className='d-flex justify-content-center'>
                                    <Image src={CertifIDFooter} />
                                </Col>
                            </Row>
                        ) : null}

                        {hideBottomBiometricsLink ? null : (
                            <a href={biometricPolicy} className='text-center' target='_blank' rel='noreferrer' onClick={clickBiometricsPolicyLink}>
                                {t("selfieConsentModal.biometricPolicy")}
                            </a>
                        )}

                        {routerVersion !== "idndemo" ? null : <p>{t("simulatorData")}</p>}
                    </Stack>
                </>
            )}
        </Background>
    );
};

export default SelfieConsentModal;
