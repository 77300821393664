import { useEffect, useRef } from "react";
import Background from "../../components/Background/Background.tsx";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import LogoAndTimer from "../../components/LogoAndTimer/LogoAndTimer.tsx";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";
import { useLogEventMutation } from "../../api/api.ts";
import createLogEventBody from "../../utils/createLogEventBody.js";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";

const TimeoutPage = () => {
    const { t } = useTranslation();
    const [logEvent, logEventResponse] = useLogEventMutation();
    const errorRedirectUrl = useSelector((state: RootState) => state.user.errorRedirectUrl);
    const sessionStorageErrorUrl = sessionStorage.getItem("errorRedirectUrl");
    const errorLink = errorRedirectUrl ? errorRedirectUrl : sessionStorageErrorUrl !== "undefined" ? sessionStorageErrorUrl : null;
    const hasFiredLogEvent = useRef(false);

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.timeout.navToPage.status));
    }, []);

    useEffect(() => {
        const sendRedirectLog = async () => {
            try {
                if (logEventResponse.isSuccess && errorLink && !hasFiredLogEvent.current) {
                    hasFiredLogEvent.current = true;
                    logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrl.status));
                    if (logEventResponse.isSuccess) {
                        window.location.reload();
                        window.location.replace(errorLink);
                    } else if (logEventResponse.isError && !logEventResponse.isSuccess && !logEventResponse.isLoading) {
                        logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrlFailure.status));
                    }
                }
            } catch {
                logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrlFailure.status));
            }
        };
        sendRedirectLog();
    }, [logEventResponse.isSuccess, logEventResponse.isError, errorLink]);

    const tokenTimeoutPage = getCompanyConfig("tokenTimeoutPage");

    if (tokenTimeoutPage) {
        return tokenTimeoutPage;
    }

    return (
        <>
            {!errorRedirectUrl && (
                <Background>
                    <LogoAndTimer />
                    <h3 className='text-center mt-5'>{t("timeoutPage")}</h3>
                </Background>
            )}
        </>
    );
};

export default TimeoutPage;
