import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";

const RentACenterBiometricsPage = () => {
    const { t } = useTranslation();
    return (
        <Col xs={11}>
            <p className='text-start'>{t("selfieConsentModal.iAgree")}</p>
        </Col>
    );
};
export default RentACenterBiometricsPage;
